export default [
  {
    key: "imageId",
    initKey: "image",
    label: "field.image",
    type: "async-single-image",
    path: "item",
    pw: 200,
    ph: 200,
    fullWidth: false,
    showUploadHistory: false,
    cols: 12,
  },
  {
    key: "no",
    label: "field.no",
    rules: "required|max:50",
    type: "text",
  },
  {
    hide: true,
    cols: 6,
  },
  {
    key: "itemCode",
    label: "field.itemCode",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "description",
    label: "field.description",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "companyCode",
    label: "field.companyCode",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "companyName",
    label: "field.companyName",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "location",
    label: "field.location",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "quantity",
    label: "field.quantity",
    rules: "required|min:1",
    type: "text",
  },
  {
    label: "breadcrumb.leasedAgreement",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "startDate",
    label: "field.startDate",
    rules: "required|max:50",
    type: "text",
  },
  {
    key: "endDate",
    label: "field.endDate",
    rules: "required|max:50",
    type: "text",
  },
  {
    label: "breadcrumb.evaluation",
    icon: "CheckSquareIcon",
    type: "divider",
  },
  {
    key: "cfa",
    label: "field.cfa",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    key: "eng",
    label: "field.eng",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    key: "opd",
    label: "field.opd",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    key: "hrm",
    label: "field.hrm",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    key: "quality",
    label: "field.quality",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    key: "fod",
    label: "field.fod",
    rules: "required|min:1",
    type: "text",
    // suffix: "%",
  },
  {
    label: "breadcrumb.price",
    icon: "DollarSignIcon",
    type: "divider",
  },
  {
    key: "estimatedMarketPrice",
    label: "field.estimatedMarketPrice",
    rules: "required",
    type: "currency",
    suffix: "$",
  },
  {
    key: "nvbPrice",
    label: "field.nvbPrice",
    rules: "required",
    type: "currency",
    suffix: "$",
  },
  {
    key: "qualityPrice",
    label: "field.qualityPrice",
    rules: "required",
    type: "currency",
    suffix: "$",
  },
  {
    key: "unitPrice",
    label: "field.unitPrice",
    rules: "required",
    type: "currency",
    suffix: "$",
  },
  {
    key: "status",
    label: "field.status",
    rules: "required|max:50",
    type: "text",
    cols: 6,
  },
  {
    key: "remark",
    label: "field.remark",
    rules: "max:500",
    type: "textarea",
    cols: 12,
  },
  {
    key: "isAvailable",
    label: "field.available",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
